:root {
	--color-primary-1: oklch(90% 0.056 300);
	--color-primary-2: oklch(80% 0.118 300);
	--color-primary-3: oklch(70% 0.185 300);
	--color-primary-4: oklch(60% 0.258 300);
	--color-primary-5: oklch(50% 0.266 300);
	--color-primary-6: oklch(40% 0.213 300);
	--color-primary-7: oklch(30% 0.16 300);
	--color-primary-8: oklch(20% 0.106 300);
	--color-primary-9: oklch(10% 0.053 300);

	--color-primary: var(--color-primary-5);
	--color-primary-text: var(--color-primary-1);

	--color-background: var(--color-primary-2);
	--color-background-text: var(--color-primary-9);
}

@media (color-gamut: p3) {
	:root {
		--color-primary-1: oklch(90% 0.062 300);
		--color-primary-2: oklch(80% 0.13 300);
		--color-primary-3: oklch(70% 0.202 300);
		--color-primary-4: oklch(60% 0.278 300);
		--color-primary-5: oklch(50% 0.277 300);
		--color-primary-6: oklch(40% 0.222 300);
		--color-primary-7: oklch(30% 0.166 300);
		--color-primary-8: oklch(20% 0.111 300);
		--color-primary-9: oklch(10% 0.055 300);
	}
}

@media (color-gamut: rec2020) {
	:root {
		--color-primary-1: oklch(90% 0.064 300);
		--color-primary-2: oklch(80% 0.132 300);
		--color-primary-3: oklch(70% 0.205 300);
		--color-primary-4: oklch(60% 0.282 300);
		--color-primary-5: oklch(50% 0.306 300);
		--color-primary-6: oklch(40% 0.245 300);
		--color-primary-7: oklch(30% 0.184 300);
		--color-primary-8: oklch(20% 0.122 300);
		--color-primary-9: oklch(10% 0.061 300);
	}
}

@media (prefers-color-scheme: dark) {
	:root {
		--color-background: var(--color-primary-8);
		--color-background-text: var(--color-primary-1);
	}
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
